import { useAnimation, useInView, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { SectionHeader } from "../../utils/SectionHeader.jsx";
import styles from "./Videos.module.css";
import video1 from "../../../media/video 1.mp4";
import video2 from "../../../media/video 2.mp4";
import video3 from "../../../media/video 3.mp4";
import video4 from "../../../media/video 4.mp4";

function Videos() {
  const controls = useAnimation();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [isInView, controls]);

  return (
    <section className="section-wrapper" id="animations" style={{'padding':'7.2rem 2.4rem 0rem 2.4rem'}}>
      <SectionHeader title="Animations" dir="r" />
      <div className={styles.headings}>
        <p>Freelance Work ❤️</p>
      </div>
      <div className={styles.container} ref={ref}>
        <motion.div
          className={styles.paper}
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5 }}
        >
          <video className={styles.poster} src={video1} autoPlay muted loop playsInline/>
        </motion.div>
        <motion.div
          className={styles.paper}
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5 }}
        >
          <video className={styles.poster} src={video2} autoPlay muted loop playsInline/>
        </motion.div>
        <motion.div
          className={styles.paper}
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5 }}
        >
          <video className={styles.poster} src={video3} autoPlay muted loop playsInline/>
        </motion.div>
        <motion.div
          className={styles.paper}
          variants={{
            hidden: { opacity: 0, y: 100 },
            visible: { opacity: 1, y: 0 },
          }}
          initial="hidden"
          animate={controls}
          transition={{ duration: 0.5 }}
        >
          <video className={styles.poster} src={video4} autoPlay muted loop playsInline/>
        </motion.div>
      </div>
    </section>
  );
}

export default Videos;
