import { SectionHeader } from "../../utils/SectionHeader.jsx";
import { Project } from "./Project";
import styles from "./projects.module.scss";
import expenseTracker from "../../../media/projects/expenseTracker.png";
import ImageDownloader from "../../../media/projects/ImageDownloader.png";
import tictacto from "../../../media/projects/tictacto.png";
import clubhub from "../../../media/projects/clubhub.png";
import portal2 from "../../../media/projects/portal2.jpeg";
import music from "../../../media/projects/music.jpeg";

export const Projects = () => {
  return (
    <section className="section-wrapper" id="projects">
      <SectionHeader title="Projects" dir="l" />
      <div className={styles.projects}>
        {projects.map((project) => {
          return <Project key={project.title} {...project} />;
        })}
      </div>
    </section>
  );
};

const projects = [
  {
    title: "Management Portal",
    imgSrc: portal2,
    code: "https://github.com/nitishkumar333/Club-Management",
    projectLink: "https://rkgit-clubportal.netlify.app/",
    tech: ["Reactjs", "Nodejs", "Expressjs", "MongoDb", "Router", "Material UI"],
    description:
      "Are you tired of the hassles of managing college events and societies? Look no further! I'm thrilled to share my creation, a comprehensive solution that simplifies planning and enhances management.",
    modalContent: (
      <>
        <p>
          💡Key Features:<br/>
          ✅ CRUD operations (create,remove,update,delete)<br/>
          ✅ User-friendly Interface with charts<br/>
          ✅ Authentication and Authorization<br/>
          ✅ Members Management<br/>
          ✅ Events Management<br/>
        </p>
        <p>
          Libraies Used:<br/>
          -&gt; Material UI, Recharts, SweetAlert, React Router, Jsonwebtoken, Multer<br/>
        </p>
      </>
    ),
  },
  {
    title: "ClubHub",
    imgSrc: clubhub,
    code: "https://github.com/nitishkumar333/Club-Management-System",
    projectLink: "https://clubhub-rkgit.netlify.app/",
    tech: ["Reactjs", "Firebase", "CSS", "SweetAlert", "HTML"],
    description:
      "Allows users to interact with data, enabling them to create, view, modify, and delete records effortlessly.",
    modalContent: (
      <>
        <p>
        I developed a web application using ReactJS and Firebase that seamlessly incorporates CRUD (Create, Read, Update, Delete) operations.
        </p>
        <p>
        Leveraging React's robust front-end capabilities and Firebase's real-time database and authentication features, the application delivers a responsive and secure user experience for managing data efficiently.
        For testing :-
        email - zzz@gmail.com
        password - 1234567890
        </p>
      </>
    ),
  },
  {
    title: "Music Player",
    imgSrc: music,
    code: "https://github.com/nitishkumar333/Music-Player",
    projectLink: "https://music-mafia.netlify.app/",
    tech: ["Reactjs", "HTML", "SASS"],
    description:
      "This project features an intuitive user interface, allowing seamless, song selection, and playback controls.",
    modalContent: (
      <>
        <p>I developed a Music player using ReactJS, showcasing my front-end development skills. </p>
        <p>
        Leveraging React's component-based architecture, I implemented features like play, pause, skip, and volume control, enhancing the user's music listening experience.
        </p>
      </>
    ),
  },
  {
    title: "Image Downloader",
    imgSrc: ImageDownloader,
    code: "https://www.github.com",
    projectLink: "https://photo-downloader.netlify.app/",
    tech: ["Javascript", "Api", "HTML", "CSS"],
    description:
      "This platform empowers users to search, preview, and download images effortlessly. Through this project, I demonstrated my proficiency in API integration.",
    modalContent: (
      <>
        <p>
          I designed and implemented an image downloader website using JavaScript and the Pexels API, underscoring my web development expertise.
        </p>
        <p>
        This platform empowers users to search, preview, and download high-quality images effortlessly. Through this project, I demonstrated my proficiency in front-end and API integration, enhancing user experiences and accessibility.
        </p>
        <p>
        This endeavor showcases my skills in web development, showcasing my ability to create functional and practical applications.
        </p>
      </>
    ),
  },
  {
    title: "Glassmorphic Expense Tracker",
    imgSrc: expenseTracker,
    code: "https://github.com/nitishkumar333/Expense-Tracker-with-Bar-graph",
    projectLink: "https://track-expenses-reactui.netlify.app/",
    tech: ["Reactjs", "HTML", "CSS", "Glassmorphism", "UI"],
    description:
      "The main aim for this project was to highlight my ability to create practical and visually appealing web applications.",
    modalContent: (
      <>
        <p>
        I developed an efficient expense tracker app using ReactJS, showcasing my proficiency in web development.
        </p>
        <p>
          This user-friendly application allows users to easily record and categorize their expenses, providing real-time insights into their financial habits. Through this project, I demonstrated my skills in front-end development, data management, and UI/UX design, highlighting my ability to create practical and visually appealing web applications.
        </p>
      </>
    ),
  },
  {
    title: "Tic-Tac-Toe",
    imgSrc: tictacto,
    code: "https://github.com/nitishkumar333/tictactoe",
    projectLink: "https://zerokatas.netlify.app/",
    tech: ["HTML", "CSS", "JAVASCRIPT"],
    description:
      "This project offers users an engaging gaming experience, featuring intuitive gameplay and a responsive user interface.",
    modalContent: (
      <>
        <p>I created a dynamic and interactive Tic-Tac-Toe game using JavaScript, highlighting my proficiency in web development and JavaScript programming.</p>
        <p>
        I demonstrated my skills in front-end development, algorithmic logic, and event handling, showcasing my ability to build enjoyable and functional web-based applications
        </p>
      </>
    ),
  },
];
