import { SectionHeader } from "../../utils/SectionHeader.jsx";
import { useAnimation, useInView, motion, stagger } from "framer-motion";
import { useEffect, useRef } from "react";
import styles from "./skills.module.scss";
import html from "../../../media/html.png";
import css from "../../../media/css.png";
import js from "../../../media/javascript.png";
import react from "../../../media/react.png";
import node from "../../../media/node.png";
import express from "../../../media/express.png";
import mongodb from "../../../media/mongodb.png";
import github from "../../../media/github.png";
import git from "../../../media/gitimage.png";
import firebase from "../../../media/firebase.png";
import sass from "../../../media/sass.png";
import mu5 from "../../../media/mu5.png";

const skills = [
  { id: 1, image: html },
  { id: 2, image: css },
  { id: 3, image: js },
  { id: 4, image: react },
  { id: 5, image: node },
  { id: 6, image: express },
  { id: 7, image: mongodb },
  { id: 8, image: git },
  { id: 9, image: sass },
  { id: 10, image: mu5 },
  { id: 11, image: firebase },
  { id: 12, image: github },
];

export default function Skills() {
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  useEffect(() => {
    if (isInView) {
      controls.start("show");
    } else {
      controls.start("hidden");
    }
  }, [isInView, controls]);

  const variants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.8
      },
    },
  };

  return (
    <div className="section-wrapper" id="skills" ref={ref} style={{
      paddingBottom: "0px"
    }}>
      <SectionHeader title="Skills" dir="r" />
      <motion.ul
        className={styles.articles}
        initial="hidden"
        animate={controls}
        variants={variants}
      >
        {skills.map((skill, i) => (
          <motion.div
            key={skill.id}
            className={styles.skill}
            variants={item}
            // animate={{ opacity: 1, translateX: 0, translateY: 0 }}
            // transition={{ duration: 0.3, delay: 0.5 }}
            // transition={{type: 'spring'}}
          >
            <img src={skill.image} alt="" />
          </motion.div>
        ))}
      </motion.ul>
    </div>
  );
}
