import styles from "./headinglinks.module.scss";
import { motion } from "framer-motion";

export const MyLinks = () => {
  return (
    <div className={styles.links}>
      <motion.span
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.0 }}
      >
        <a
          href="https://www.linkedin.com/in/nitishkmrr/"
          target="_blank"
          rel="noreferrer"
        >
          {/* <AiFillLinkedin size="4.4rem" /> */}
          <lottie-player
            src="https://lottie.host/c59fc99f-812b-4f14-aa66-c4ba0dc3139f/RviagFcNeM.json"
            background="#ffffff"
            speed="1"
            style={{ width: "6rem", height: "6rem" }}
            direction="1"
            mode="normal"
            loop
            autoplay
          ></lottie-player>
        </a>
      </motion.span>

      <motion.span
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <a
          href="https://github.com/nitishkumar333"
          target="_blank"
          rel="noreferrer"
        >
          {/* <AiFillGithub size="4.4rem" /> */}
          <lottie-player
            src="https://lottie.host/da613eee-7be5-4f6c-8fc0-65e6f2231299/Fur1bM3rfn.json"
            background="#FFFFFF"
            speed="1"
            style={{ width: "6rem", height: "6rem" }}
            direction="1"
            mode="normal"
            loop
            autoplay
          ></lottie-player>
        </a>
      </motion.span>
      

      <motion.span
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <a
          href="https://www.youtube.com/@AftereffectsBasicsTutorials"
          target="_blank"
          rel="noreferrer"
        >
          {/* <AiFillInstagram size="4.4rem" /> */}
          <lottie-player
            src="https://lottie.host/47f9e2b4-01b4-443c-9472-5d291041c6f0/c3qR54HN9a.json"
            background="#ffffff"
            speed="1"
            direction="1"
            mode="normal"
            loop
            autoplay
            style={{ width: "6rem", height: "6rem" }}
          ></lottie-player>
        </a>
      </motion.span>

      <motion.span
        initial={{ y: -30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <a
          href="https://leetcode.com/nitishkmrr/"
          target="_blank"
          rel="noreferrer"
        >
          {/* <AiFillGithub size="4.4rem" /> */}
          <lottie-player
            src="https://lottie.host/5775d96b-42bc-4244-84c0-951b065fa25b/SUBcZpf4kS.json"
            background="#FFFFFF"
            speed="1"
            style={{ width: "5.2rem", height: "5.2rem" }}
            direction="1"
            mode="normal"
            loop
            autoplay
          ></lottie-player>
        </a>
      </motion.span>
    </div>
  );
};
