import { StandardButton } from "../../buttons/StandardButton.jsx";
import { Reveal } from "../../utils/Reveal.jsx";
import { DotGrid } from "./DotGrid";
import { MyLinks } from "../../nav/components/MyLinks.jsx";
import styles from "./hero.module.scss";

export const Hero = () => {
  return (
    <section className={` ${styles.hero}`} style={{margin:0}}>
      <div className={styles.heroGrid}>
        <div className={styles.copyWrapper}>
          <Reveal>
            <h1 className={styles.title}>
              I&apos;m <span> </span>
              <div className={styles.letter}>
                <div className={styles.letter2}><lottie-player src="https://lottie.host/0c975b5d-ae4d-404e-9720-51abcb0e2bd8/COQ6xVWM0z.json" background="#fff" speed="1" direction="1" mode="normal" loop autoplay></lottie-player></div>
              </div>
              <div className={styles.letter}>
                <div className={styles.letter2}><lottie-player src="https://lottie.host/ad468ede-e662-4d7f-8fce-2bdcf278b079/LXNRSiZ26c.json" background="#fff" speed="1" direction="1" mode="normal" loop autoplay></lottie-player></div>
              </div>
              <span>.</span>
            </h1>
          </Reveal>
          <Reveal>
            <h2 className={styles.subTitle}>
              I&apos;m a <span>Web Developer</span>
            </h2>
          </Reveal>
          <Reveal>
            <p className={styles.aboutCopy}>
              I like to craft solid and scalable products with great user
              experience.
            </p>
          </Reveal>
          <Reveal>
            <StandardButton
              onClick={() =>
                document.getElementById("contact")?.scrollIntoView()
              }
            >
              Contact me
            </StandardButton>
          </Reveal>
          <MyLinks/>
        <div className={styles.scrollUp}>
          <div className={styles.scrollUp2}>
            <lottie-player src="https://lottie.host/bf2c8736-ba2c-4d74-b0ef-5a9a8e40f86d/sMk9HIMniZ.json" background="#ffffff" speed="1" direction="1" mode="normal" loop autoplay></lottie-player>
          </div>
        </div>
        </div>
      </div>
      <DotGrid />
    </section>
  );
};
