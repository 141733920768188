import { motion, useScroll, useTransform } from "framer-motion";
import styles from "./home.module.scss";
import { Heading } from "../nav/Heading";
import { Hero } from "./hero/Hero";
import { About } from "./about/About";
import { Projects } from "./projects/Projects";
// import { Experience } from "./experience/Experience";
import { Contact } from "./contact/Contact";
import Videos from "./Videos/Videos.jsx";
import Skills from "./skills/Skills.jsx";

const Home = () => {
  const { scrollY } = useScroll();
  const heroY = useTransform(scrollY, [0, 2000], [0, 300]);
  const otherY = useTransform(scrollY, [0, 2000], [0, -300]);

  return (
    <div className={styles.home}>
      {/* <main id="main"> */}
      <Heading />
      <motion.div style={{ y: heroY }}>
        <Hero />
      </motion.div>
      <motion.div className="lowerSections" style={{ y: otherY }}>
        <About />
        <Skills />
        <Projects />
        {/* <Experience /> */}
        <Videos />
        <Contact />
      </motion.div>
      {/* </main> */}
    </div>
  );
};

export default Home;
