import styles from "./heading.module.scss";
import { MyLinks } from "./components/MyLinks";


export const Heading = () => {

  return (
    <header className={`${styles.heading} xyz`}>
        <div class={styles.logo} className="primary-navigation">
            NKmotions
        </div>
        <nav>
          <ul>
            <li><a href="#about">About</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#experience">Experience</a></li>
            <li><a href="#contact">Contact</a></li>
            <li><a href="#animations">Animations</a></li>
          </ul>
        </nav>
    </header>
    // <header className={styles.heading}>
    //   <MyLinks />
    //     <OutlineButton onClick={() => window.open("/JOHN CARLO DEVERA.pdf")}>
    //       My resume
    //     </OutlineButton>
    // </header>
  );
};
