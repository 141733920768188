import { MyLinks } from "../../nav/components/MyLinks.jsx";
import { Reveal } from "../../utils/Reveal.jsx"
import { SectionHeader } from "../../utils/SectionHeader.jsx";
import styles from "./about.module.scss";
import { Stats } from "./Stats";
import { AiOutlineArrowRight } from "react-icons/ai";

export const About = () => {
  return (
    <section id="about" className="section-wrapper">
      <div style={{marginTop:"5rem"}}>
        <SectionHeader title="About" dir="l"/>
      </div>
      <div className={styles.about}>
        <div>
          <Reveal>
            <p className={`${styles.aboutText} ${styles.highlightFirstLetter}`}>
              Hey! I&apos;m Nitish Kumar, and I&apos;m currently pursuing Bachelor of Technology in Computer Science from Raj Kumar Goel Institute of Technology.
              <br/><br/>
              I have experience working in the web development world, with a focus on frontend development. I&apos;m passionate about creating beautiful, responsive websites that provide a great user experience.
             </p>
          </Reveal>
          <Reveal>
            <p className={styles.aboutText}>
            My skills include HTML, CSS, JavaScript, and various frontend frameworks like React. I&apos;ve also worked with backend technologies like Node.js and databases like Firebase and MongoDb, allowing me to build full-stack applications.
            </p>
          </Reveal>
          <Reveal>
            <p className={styles.aboutText}>
              When I&apos;m not coding, I enjoy making Motion graphics and 2d animations using After Effects.
            </p>
          </Reveal>
          <Reveal>
            <p className={styles.aboutText}>
              I&apos;m always looking for new challenges and opportunities to learn and grow as a developer. 
              If you&apos;re interested in working together or have any questions, please don&apos;t hesitate to get in touch!
              🔗
            </p>
          </Reveal>
          <Reveal>
            <div className={styles.links}>
              <div className={styles.linksText}>
                <span>My links</span>
                <AiOutlineArrowRight />
              </div>
              <MyLinks />
            </div>
          </Reveal>
        </div>
        <Stats />
      </div>
    </section>
  );
};
